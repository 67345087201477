import { service, serviceMock } from './service'
import { config } from './config'

// eslint-disable-next-line camelcase
const { default_headers } = config

const request = (option: any) => {
  console.log('option', option)
  const { url, method, params, data, headersType, responseType } = option
  return service({
    url,
    method,
    params,
    data,
    responseType,
    headers: {
      // eslint-disable-next-line camelcase
      'Content-Type': headersType || default_headers,
    },
  })
}

const requestMock = (option: any) => {
  const { url, method, params, data, headersType, responseType } = option
  return serviceMock({
    url,
    method,
    params,
    data,
    responseType,
    headers: {
      // eslint-disable-next-line camelcase
      'Content-Type': headersType || default_headers,
    },
  })
}
export default {
  get: <T = any>(option: any) => {
    return request({ method: 'get', ...option }) as unknown as T
  },
  getBody: <T = any>(option: any) => {
    return request({ method: 'get', ...option }) as unknown as T
  },
  post: <T = any>(option: any) => {
    return request({ method: 'post', ...option }) as unknown as T
  },
  delete: <T = any>(option: any) => {
    return request({
      method: 'delete',
      ...option,
    }) as unknown as T
  },
  put: <T = any>(option: any) => {
    return request({ method: 'put', ...option }) as unknown as T
  },
  download: <T = any>(option: any) => {
    return request({
      method: 'post',
      responseType: 'blob',
      ...option,
    }) as unknown as T
  },
  mockPost: <T = any>(option: any) => {
    return requestMock({
      method: 'post',
      ...option,
    }) as unknown as T
  },

  filePost: <T = any>(option: any) => {
    return request({
      method: 'post',
      headersType: 'multipart/form-data',
      ...option,
    }) as unknown as T
  },
}
