import revive_payload_client_4sVQNw7RlN from "D:/DYB_Project/DYB-Windos-Nuxt/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "D:/DYB_Project/DYB-Windos-Nuxt/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "D:/DYB_Project/DYB-Windos-Nuxt/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "D:/DYB_Project/DYB-Windos-Nuxt/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "D:/DYB_Project/DYB-Windos-Nuxt/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "D:/DYB_Project/DYB-Windos-Nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "D:/DYB_Project/DYB-Windos-Nuxt/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "D:/DYB_Project/DYB-Windos-Nuxt/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "D:/DYB_Project/DYB-Windos-Nuxt/.nuxt/sentry-client-config.mjs";
import plugin_client_UYiXMU8ZyN from "D:/DYB_Project/DYB-Windos-Nuxt/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_t2GMTTFnMT from "D:/DYB_Project/DYB-Windos-Nuxt/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "D:/DYB_Project/DYB-Windos-Nuxt/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_AUP22rrBAc from "D:/DYB_Project/DYB-Windos-Nuxt/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import chunk_reload_3Gzowdx4Y2 from "D:/DYB_Project/DYB-Windos-Nuxt/plugins/chunk-reload.ts";
import error_handler_kEP5FliEXj from "D:/DYB_Project/DYB-Windos-Nuxt/plugins/error-handler.ts";
import message_gbbzzLXzMP from "D:/DYB_Project/DYB-Windos-Nuxt/plugins/message.ts";
import screenSet_GTELwvE0bO from "D:/DYB_Project/DYB-Windos-Nuxt/plugins/screenSet.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_client_UYiXMU8ZyN,
  plugin_t2GMTTFnMT,
  chunk_reload_client_UciE0i6zes,
  plugin_AUP22rrBAc,
  chunk_reload_3Gzowdx4Y2,
  error_handler_kEP5FliEXj,
  message_gbbzzLXzMP,
  screenSet_GTELwvE0bO
]