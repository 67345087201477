import { default as indexF4iTMJCNaWMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/alarm/index.vue?macro=true";
import { default as essayTypeReg_BACK4KLFo8uB5iMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/assignments/[idx]/essayTypeReg_BACK.vue?macro=true";
import { default as essayTypeRegLvqDMYbbVuMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/assignments/[idx]/essayTypeReg.vue?macro=true";
import { default as indexDgeE4dDc3tMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/assignments/[idx]/index.vue?macro=true";
import { default as textTypeReg_BACKBaJVdUje5iMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/assignments/[idx]/textTypeReg_BACK.vue?macro=true";
import { default as textTypeRegQFJT10HC7TMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/assignments/[idx]/textTypeReg.vue?macro=true";
import { default as dayRwOW1XwXPzMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/assignments/day.vue?macro=true";
import { default as image_45pickerfYJS6uF4vmMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/assignments/image-picker.vue?macro=true";
import { default as indexMWSF2iTyYOMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/assignments/index.vue?macro=true";
import { default as changeYXl2xjgnSkMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/bus/change.vue?macro=true";
import { default as indexDlOJQLcaWQMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/bus/index.vue?macro=true";
import { default as routeMQ7Fbwx5wfMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/bus/route.vue?macro=true";
import { default as indexb9V1CWfDzQMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/class/index.vue?macro=true";
import { default as indexhNJJew1YQWMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/contentLibrary/index.vue?macro=true";
import { default as searchr8X1qk0fF2Meta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/contentLibrary/search.vue?macro=true";
import { default as _91idx_93Gx78SdPMkkMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/daily/[idx].vue?macro=true";
import { default as indexpyZHqq3H7fMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/daily/index.vue?macro=true";
import { default as dybGcshWa8x4hMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/dyb.vue?macro=true";
import { default as detail_BACKSL2l9UssP3Meta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/essay/detail_BACK.vue?macro=true";
import { default as detail_BACK2EKCeqf5sO4Meta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/essay/detail_BACK2.vue?macro=true";
import { default as details3TY5JZooKMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/essay/detail.vue?macro=true";
import { default as indexypjBb2bckKMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/essay/index.vue?macro=true";
import { default as resultsNY9RhCldpMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/essay/result.vue?macro=true";
import { default as indexCezOt7QSmQMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/findId/index.vue?macro=true";
import { default as indexRQRS5GUBBZMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/findPw/index.vue?macro=true";
import { default as detailK9zFDiMMhvMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/formative/detail.vue?macro=true";
import { default as indexodsfj3YTlAMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/grade/index.vue?macro=true";
import { default as indexMAwxtL7MprMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/index.vue?macro=true";
import { default as assignment32Yu83907ZMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/learning/assignment.vue?macro=true";
import { default as reportboQPW90eGEMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/learning/report.vue?macro=true";
import { default as index3KA4dWkl8kMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/login/index.vue?macro=true";
import { default as index2mcDKiFH8qMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/main/index.vue?macro=true";
import { default as indexKFhUIKVZiUMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/media/index.vue?macro=true";
import { default as indexmVyvLux0rMMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/menu/index.vue?macro=true";
import { default as changePwt652uXEiLRMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/myinfo/changePw.vue?macro=true";
import { default as findPwwybKKzaiEJMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/myinfo/findPw.vue?macro=true";
import { default as indexruj2HoVfYRMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/myinfo/index.vue?macro=true";
import { default as parentsInfoORBw3Fc03vMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/myinfo/parentsInfo.vue?macro=true";
import { default as indexbJQoVW5mCjMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/myinfo/userInfo/index.vue?macro=true";
import { default as searchAddressefeGappASDMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/myinfo/userInfo/searchAddress.vue?macro=true";
import { default as searchSchooldtgqfxlh5NMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/myinfo/userInfo/searchSchool.vue?macro=true";
import { default as _91idx_934cSM1IY4sJMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/notice/[idx].vue?macro=true";
import { default as detailqSo0Apnhz5Meta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/notice/detail.vue?macro=true";
import { default as indexlXW9WeIUOoMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/notice/index.vue?macro=true";
import { default as _91key_93YyrkXhyRtvMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/payment/book/[key].vue?macro=true";
import { default as indexDvOcVwZAYBMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/payment/book/index.vue?macro=true";
import { default as _91key_93FRAYFKWao5Meta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/payment/edu/[key].vue?macro=true";
import { default as index1ktBhE27GTMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/payment/edu/index.vue?macro=true";
import { default as errorq6gs1yJodgMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/payment/error.vue?macro=true";
import { default as fail9trekHFPLnMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/payment/fail.vue?macro=true";
import { default as indexzszgv4uWHEMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/payment/index.vue?macro=true";
import { default as processaJDvwYHzk5Meta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/payment/process.vue?macro=true";
import { default as successWlaXXQvILvMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/payment/success.vue?macro=true";
import { default as token212h2HiisuMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/redirect/token.vue?macro=true";
import { default as index92fi2Src5eMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/reject/index.vue?macro=true";
import { default as index8yXF8Gf3pxMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/setting/index.vue?macro=true";
import { default as privacyrxV8HlafuGMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/setting/privacy.vue?macro=true";
import { default as termgWVbHutvx7Meta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/setting/term.vue?macro=true";
import { default as _01QzDrv3ehTZMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/01.vue?macro=true";
import { default as audioNgg0U5xcncMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/audio.vue?macro=true";
import { default as bottomUgNFETqhQ8Meta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/bottom.vue?macro=true";
import { default as bottom29kJlwcmedqMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/bottom2.vue?macro=true";
import { default as bottom36vF6EWTtMlMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/bottom3.vue?macro=true";
import { default as children_select_01JYgeT4yPq5Meta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/children_select_01.vue?macro=true";
import { default as children_select_02KSgemCFzVBMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/children_select_02.vue?macro=true";
import { default as children_select0Mvwetm2CsMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/children_select.vue?macro=true";
import { default as circle_45chartFLDUa0dYXVMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/circle-chart.vue?macro=true";
import { default as datepickerarSCkMwc80Meta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/datepicker.vue?macro=true";
import { default as deviceAHKZYpW65aMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/device.vue?macro=true";
import { default as edit0z2FWIcUhNMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/edit.vue?macro=true";
import { default as iframe1C7NpVMFtNMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/iframe.vue?macro=true";
import { default as image_45pickerEmvATCLfiYMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/image-picker.vue?macro=true";
import { default as indexwkgGO797dlMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/index.vue?macro=true";
import { default as orientationm4jZdZtOkxMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/orientation.vue?macro=true";
import { default as photo_select_01541Yv1wTjzMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/photo_select_01.vue?macro=true";
import { default as photo_select_02VjKXe5fg8gMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/photo_select_02.vue?macro=true";
import { default as photoFFGVNGzZ1lMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/photo.vue?macro=true";
import { default as text7TwdG0GhwvMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/text.vue?macro=true";
import { default as tossbld0mqu8BMMeta } from "D:/DYB_Project/DYB-Windos-Nuxt/pages/test/toss.vue?macro=true";
export default [
  {
    name: indexF4iTMJCNaWMeta?.name ?? "alarm",
    path: indexF4iTMJCNaWMeta?.path ?? "/alarm",
    meta: indexF4iTMJCNaWMeta || {},
    alias: indexF4iTMJCNaWMeta?.alias || [],
    redirect: indexF4iTMJCNaWMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/alarm/index.vue").then(m => m.default || m)
  },
  {
    name: essayTypeReg_BACK4KLFo8uB5iMeta?.name ?? "assignments-idx-essayTypeReg_BACK",
    path: essayTypeReg_BACK4KLFo8uB5iMeta?.path ?? "/assignments/:idx()/essayTypeReg_BACK",
    meta: essayTypeReg_BACK4KLFo8uB5iMeta || {},
    alias: essayTypeReg_BACK4KLFo8uB5iMeta?.alias || [],
    redirect: essayTypeReg_BACK4KLFo8uB5iMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/assignments/[idx]/essayTypeReg_BACK.vue").then(m => m.default || m)
  },
  {
    name: essayTypeRegLvqDMYbbVuMeta?.name ?? "assignments-idx-essayTypeReg",
    path: essayTypeRegLvqDMYbbVuMeta?.path ?? "/assignments/:idx()/essayTypeReg",
    meta: essayTypeRegLvqDMYbbVuMeta || {},
    alias: essayTypeRegLvqDMYbbVuMeta?.alias || [],
    redirect: essayTypeRegLvqDMYbbVuMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/assignments/[idx]/essayTypeReg.vue").then(m => m.default || m)
  },
  {
    name: indexDgeE4dDc3tMeta?.name ?? "assignments-idx",
    path: indexDgeE4dDc3tMeta?.path ?? "/assignments/:idx()",
    meta: indexDgeE4dDc3tMeta || {},
    alias: indexDgeE4dDc3tMeta?.alias || [],
    redirect: indexDgeE4dDc3tMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/assignments/[idx]/index.vue").then(m => m.default || m)
  },
  {
    name: textTypeReg_BACKBaJVdUje5iMeta?.name ?? "assignments-idx-textTypeReg_BACK",
    path: textTypeReg_BACKBaJVdUje5iMeta?.path ?? "/assignments/:idx()/textTypeReg_BACK",
    meta: textTypeReg_BACKBaJVdUje5iMeta || {},
    alias: textTypeReg_BACKBaJVdUje5iMeta?.alias || [],
    redirect: textTypeReg_BACKBaJVdUje5iMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/assignments/[idx]/textTypeReg_BACK.vue").then(m => m.default || m)
  },
  {
    name: textTypeRegQFJT10HC7TMeta?.name ?? "assignments-idx-textTypeReg",
    path: textTypeRegQFJT10HC7TMeta?.path ?? "/assignments/:idx()/textTypeReg",
    meta: textTypeRegQFJT10HC7TMeta || {},
    alias: textTypeRegQFJT10HC7TMeta?.alias || [],
    redirect: textTypeRegQFJT10HC7TMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/assignments/[idx]/textTypeReg.vue").then(m => m.default || m)
  },
  {
    name: dayRwOW1XwXPzMeta?.name ?? "assignments-day",
    path: dayRwOW1XwXPzMeta?.path ?? "/assignments/day",
    meta: dayRwOW1XwXPzMeta || {},
    alias: dayRwOW1XwXPzMeta?.alias || [],
    redirect: dayRwOW1XwXPzMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/assignments/day.vue").then(m => m.default || m)
  },
  {
    name: image_45pickerfYJS6uF4vmMeta?.name ?? "assignments-image-picker",
    path: image_45pickerfYJS6uF4vmMeta?.path ?? "/assignments/image-picker",
    meta: image_45pickerfYJS6uF4vmMeta || {},
    alias: image_45pickerfYJS6uF4vmMeta?.alias || [],
    redirect: image_45pickerfYJS6uF4vmMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/assignments/image-picker.vue").then(m => m.default || m)
  },
  {
    name: indexMWSF2iTyYOMeta?.name ?? "assignments",
    path: indexMWSF2iTyYOMeta?.path ?? "/assignments",
    meta: indexMWSF2iTyYOMeta || {},
    alias: indexMWSF2iTyYOMeta?.alias || [],
    redirect: indexMWSF2iTyYOMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/assignments/index.vue").then(m => m.default || m)
  },
  {
    name: changeYXl2xjgnSkMeta?.name ?? "bus-change",
    path: changeYXl2xjgnSkMeta?.path ?? "/bus/change",
    meta: changeYXl2xjgnSkMeta || {},
    alias: changeYXl2xjgnSkMeta?.alias || [],
    redirect: changeYXl2xjgnSkMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/bus/change.vue").then(m => m.default || m)
  },
  {
    name: indexDlOJQLcaWQMeta?.name ?? "bus",
    path: indexDlOJQLcaWQMeta?.path ?? "/bus",
    meta: indexDlOJQLcaWQMeta || {},
    alias: indexDlOJQLcaWQMeta?.alias || [],
    redirect: indexDlOJQLcaWQMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/bus/index.vue").then(m => m.default || m)
  },
  {
    name: routeMQ7Fbwx5wfMeta?.name ?? "bus-route",
    path: routeMQ7Fbwx5wfMeta?.path ?? "/bus/route",
    meta: routeMQ7Fbwx5wfMeta || {},
    alias: routeMQ7Fbwx5wfMeta?.alias || [],
    redirect: routeMQ7Fbwx5wfMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/bus/route.vue").then(m => m.default || m)
  },
  {
    name: indexb9V1CWfDzQMeta?.name ?? "class",
    path: indexb9V1CWfDzQMeta?.path ?? "/class",
    meta: indexb9V1CWfDzQMeta || {},
    alias: indexb9V1CWfDzQMeta?.alias || [],
    redirect: indexb9V1CWfDzQMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/class/index.vue").then(m => m.default || m)
  },
  {
    name: indexhNJJew1YQWMeta?.name ?? "contentLibrary",
    path: indexhNJJew1YQWMeta?.path ?? "/contentLibrary",
    meta: indexhNJJew1YQWMeta || {},
    alias: indexhNJJew1YQWMeta?.alias || [],
    redirect: indexhNJJew1YQWMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/contentLibrary/index.vue").then(m => m.default || m)
  },
  {
    name: searchr8X1qk0fF2Meta?.name ?? "contentLibrary-search",
    path: searchr8X1qk0fF2Meta?.path ?? "/contentLibrary/search",
    meta: searchr8X1qk0fF2Meta || {},
    alias: searchr8X1qk0fF2Meta?.alias || [],
    redirect: searchr8X1qk0fF2Meta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/contentLibrary/search.vue").then(m => m.default || m)
  },
  {
    name: _91idx_93Gx78SdPMkkMeta?.name ?? "daily-idx",
    path: _91idx_93Gx78SdPMkkMeta?.path ?? "/daily/:idx()",
    meta: _91idx_93Gx78SdPMkkMeta || {},
    alias: _91idx_93Gx78SdPMkkMeta?.alias || [],
    redirect: _91idx_93Gx78SdPMkkMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/daily/[idx].vue").then(m => m.default || m)
  },
  {
    name: indexpyZHqq3H7fMeta?.name ?? "daily",
    path: indexpyZHqq3H7fMeta?.path ?? "/daily",
    meta: indexpyZHqq3H7fMeta || {},
    alias: indexpyZHqq3H7fMeta?.alias || [],
    redirect: indexpyZHqq3H7fMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/daily/index.vue").then(m => m.default || m)
  },
  {
    name: dybGcshWa8x4hMeta?.name ?? "dyb",
    path: dybGcshWa8x4hMeta?.path ?? "/dyb",
    meta: dybGcshWa8x4hMeta || {},
    alias: dybGcshWa8x4hMeta?.alias || [],
    redirect: dybGcshWa8x4hMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/dyb.vue").then(m => m.default || m)
  },
  {
    name: detail_BACKSL2l9UssP3Meta?.name ?? "essay-detail_BACK",
    path: detail_BACKSL2l9UssP3Meta?.path ?? "/essay/detail_BACK",
    meta: detail_BACKSL2l9UssP3Meta || {},
    alias: detail_BACKSL2l9UssP3Meta?.alias || [],
    redirect: detail_BACKSL2l9UssP3Meta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/essay/detail_BACK.vue").then(m => m.default || m)
  },
  {
    name: detail_BACK2EKCeqf5sO4Meta?.name ?? "essay-detail_BACK2",
    path: detail_BACK2EKCeqf5sO4Meta?.path ?? "/essay/detail_BACK2",
    meta: detail_BACK2EKCeqf5sO4Meta || {},
    alias: detail_BACK2EKCeqf5sO4Meta?.alias || [],
    redirect: detail_BACK2EKCeqf5sO4Meta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/essay/detail_BACK2.vue").then(m => m.default || m)
  },
  {
    name: details3TY5JZooKMeta?.name ?? "essay-detail",
    path: details3TY5JZooKMeta?.path ?? "/essay/detail",
    meta: details3TY5JZooKMeta || {},
    alias: details3TY5JZooKMeta?.alias || [],
    redirect: details3TY5JZooKMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/essay/detail.vue").then(m => m.default || m)
  },
  {
    name: indexypjBb2bckKMeta?.name ?? "essay",
    path: indexypjBb2bckKMeta?.path ?? "/essay",
    meta: indexypjBb2bckKMeta || {},
    alias: indexypjBb2bckKMeta?.alias || [],
    redirect: indexypjBb2bckKMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/essay/index.vue").then(m => m.default || m)
  },
  {
    name: resultsNY9RhCldpMeta?.name ?? "essay-result",
    path: resultsNY9RhCldpMeta?.path ?? "/essay/result",
    meta: resultsNY9RhCldpMeta || {},
    alias: resultsNY9RhCldpMeta?.alias || [],
    redirect: resultsNY9RhCldpMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/essay/result.vue").then(m => m.default || m)
  },
  {
    name: indexCezOt7QSmQMeta?.name ?? "findId",
    path: indexCezOt7QSmQMeta?.path ?? "/findId",
    meta: indexCezOt7QSmQMeta || {},
    alias: indexCezOt7QSmQMeta?.alias || [],
    redirect: indexCezOt7QSmQMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/findId/index.vue").then(m => m.default || m)
  },
  {
    name: indexRQRS5GUBBZMeta?.name ?? "findPw",
    path: indexRQRS5GUBBZMeta?.path ?? "/findPw",
    meta: indexRQRS5GUBBZMeta || {},
    alias: indexRQRS5GUBBZMeta?.alias || [],
    redirect: indexRQRS5GUBBZMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/findPw/index.vue").then(m => m.default || m)
  },
  {
    name: detailK9zFDiMMhvMeta?.name ?? "formative-detail",
    path: detailK9zFDiMMhvMeta?.path ?? "/formative/detail",
    meta: detailK9zFDiMMhvMeta || {},
    alias: detailK9zFDiMMhvMeta?.alias || [],
    redirect: detailK9zFDiMMhvMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/formative/detail.vue").then(m => m.default || m)
  },
  {
    name: indexodsfj3YTlAMeta?.name ?? "grade",
    path: indexodsfj3YTlAMeta?.path ?? "/grade",
    meta: indexodsfj3YTlAMeta || {},
    alias: indexodsfj3YTlAMeta?.alias || [],
    redirect: indexodsfj3YTlAMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/grade/index.vue").then(m => m.default || m)
  },
  {
    name: indexMAwxtL7MprMeta?.name ?? "index",
    path: indexMAwxtL7MprMeta?.path ?? "/",
    meta: indexMAwxtL7MprMeta || {},
    alias: indexMAwxtL7MprMeta?.alias || [],
    redirect: indexMAwxtL7MprMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: assignment32Yu83907ZMeta?.name ?? "learning-assignment",
    path: assignment32Yu83907ZMeta?.path ?? "/learning/assignment",
    meta: assignment32Yu83907ZMeta || {},
    alias: assignment32Yu83907ZMeta?.alias || [],
    redirect: assignment32Yu83907ZMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/learning/assignment.vue").then(m => m.default || m)
  },
  {
    name: reportboQPW90eGEMeta?.name ?? "learning-report",
    path: reportboQPW90eGEMeta?.path ?? "/learning/report",
    meta: reportboQPW90eGEMeta || {},
    alias: reportboQPW90eGEMeta?.alias || [],
    redirect: reportboQPW90eGEMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/learning/report.vue").then(m => m.default || m)
  },
  {
    name: index3KA4dWkl8kMeta?.name ?? "login",
    path: index3KA4dWkl8kMeta?.path ?? "/login",
    meta: index3KA4dWkl8kMeta || {},
    alias: index3KA4dWkl8kMeta?.alias || [],
    redirect: index3KA4dWkl8kMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: index2mcDKiFH8qMeta?.name ?? "main",
    path: index2mcDKiFH8qMeta?.path ?? "/main",
    meta: index2mcDKiFH8qMeta || {},
    alias: index2mcDKiFH8qMeta?.alias || [],
    redirect: index2mcDKiFH8qMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/main/index.vue").then(m => m.default || m)
  },
  {
    name: indexKFhUIKVZiUMeta?.name ?? "media",
    path: indexKFhUIKVZiUMeta?.path ?? "/media",
    meta: indexKFhUIKVZiUMeta || {},
    alias: indexKFhUIKVZiUMeta?.alias || [],
    redirect: indexKFhUIKVZiUMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/media/index.vue").then(m => m.default || m)
  },
  {
    name: indexmVyvLux0rMMeta?.name ?? "menu",
    path: indexmVyvLux0rMMeta?.path ?? "/menu",
    meta: indexmVyvLux0rMMeta || {},
    alias: indexmVyvLux0rMMeta?.alias || [],
    redirect: indexmVyvLux0rMMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/menu/index.vue").then(m => m.default || m)
  },
  {
    name: changePwt652uXEiLRMeta?.name ?? "myinfo-changePw",
    path: changePwt652uXEiLRMeta?.path ?? "/myinfo/changePw",
    meta: changePwt652uXEiLRMeta || {},
    alias: changePwt652uXEiLRMeta?.alias || [],
    redirect: changePwt652uXEiLRMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/myinfo/changePw.vue").then(m => m.default || m)
  },
  {
    name: findPwwybKKzaiEJMeta?.name ?? "myinfo-findPw",
    path: findPwwybKKzaiEJMeta?.path ?? "/myinfo/findPw",
    meta: findPwwybKKzaiEJMeta || {},
    alias: findPwwybKKzaiEJMeta?.alias || [],
    redirect: findPwwybKKzaiEJMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/myinfo/findPw.vue").then(m => m.default || m)
  },
  {
    name: indexruj2HoVfYRMeta?.name ?? "myinfo",
    path: indexruj2HoVfYRMeta?.path ?? "/myinfo",
    meta: indexruj2HoVfYRMeta || {},
    alias: indexruj2HoVfYRMeta?.alias || [],
    redirect: indexruj2HoVfYRMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/myinfo/index.vue").then(m => m.default || m)
  },
  {
    name: parentsInfoORBw3Fc03vMeta?.name ?? "myinfo-parentsInfo",
    path: parentsInfoORBw3Fc03vMeta?.path ?? "/myinfo/parentsInfo",
    meta: parentsInfoORBw3Fc03vMeta || {},
    alias: parentsInfoORBw3Fc03vMeta?.alias || [],
    redirect: parentsInfoORBw3Fc03vMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/myinfo/parentsInfo.vue").then(m => m.default || m)
  },
  {
    name: indexbJQoVW5mCjMeta?.name ?? "myinfo-userInfo",
    path: indexbJQoVW5mCjMeta?.path ?? "/myinfo/userInfo",
    meta: indexbJQoVW5mCjMeta || {},
    alias: indexbJQoVW5mCjMeta?.alias || [],
    redirect: indexbJQoVW5mCjMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/myinfo/userInfo/index.vue").then(m => m.default || m)
  },
  {
    name: searchAddressefeGappASDMeta?.name ?? "myinfo-userInfo-searchAddress",
    path: searchAddressefeGappASDMeta?.path ?? "/myinfo/userInfo/searchAddress",
    meta: searchAddressefeGappASDMeta || {},
    alias: searchAddressefeGappASDMeta?.alias || [],
    redirect: searchAddressefeGappASDMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/myinfo/userInfo/searchAddress.vue").then(m => m.default || m)
  },
  {
    name: searchSchooldtgqfxlh5NMeta?.name ?? "myinfo-userInfo-searchSchool",
    path: searchSchooldtgqfxlh5NMeta?.path ?? "/myinfo/userInfo/searchSchool",
    meta: searchSchooldtgqfxlh5NMeta || {},
    alias: searchSchooldtgqfxlh5NMeta?.alias || [],
    redirect: searchSchooldtgqfxlh5NMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/myinfo/userInfo/searchSchool.vue").then(m => m.default || m)
  },
  {
    name: _91idx_934cSM1IY4sJMeta?.name ?? "notice-idx",
    path: _91idx_934cSM1IY4sJMeta?.path ?? "/notice/:idx()",
    meta: _91idx_934cSM1IY4sJMeta || {},
    alias: _91idx_934cSM1IY4sJMeta?.alias || [],
    redirect: _91idx_934cSM1IY4sJMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/notice/[idx].vue").then(m => m.default || m)
  },
  {
    name: detailqSo0Apnhz5Meta?.name ?? "notice-detail",
    path: detailqSo0Apnhz5Meta?.path ?? "/notice/detail",
    meta: detailqSo0Apnhz5Meta || {},
    alias: detailqSo0Apnhz5Meta?.alias || [],
    redirect: detailqSo0Apnhz5Meta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/notice/detail.vue").then(m => m.default || m)
  },
  {
    name: indexlXW9WeIUOoMeta?.name ?? "notice",
    path: indexlXW9WeIUOoMeta?.path ?? "/notice",
    meta: indexlXW9WeIUOoMeta || {},
    alias: indexlXW9WeIUOoMeta?.alias || [],
    redirect: indexlXW9WeIUOoMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/notice/index.vue").then(m => m.default || m)
  },
  {
    name: _91key_93YyrkXhyRtvMeta?.name ?? "payment-book-key",
    path: _91key_93YyrkXhyRtvMeta?.path ?? "/payment/book/:key()",
    meta: _91key_93YyrkXhyRtvMeta || {},
    alias: _91key_93YyrkXhyRtvMeta?.alias || [],
    redirect: _91key_93YyrkXhyRtvMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/payment/book/[key].vue").then(m => m.default || m)
  },
  {
    name: indexDvOcVwZAYBMeta?.name ?? "payment-book",
    path: indexDvOcVwZAYBMeta?.path ?? "/payment/book",
    meta: indexDvOcVwZAYBMeta || {},
    alias: indexDvOcVwZAYBMeta?.alias || [],
    redirect: indexDvOcVwZAYBMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/payment/book/index.vue").then(m => m.default || m)
  },
  {
    name: _91key_93FRAYFKWao5Meta?.name ?? "payment-edu-key",
    path: _91key_93FRAYFKWao5Meta?.path ?? "/payment/edu/:key()",
    meta: _91key_93FRAYFKWao5Meta || {},
    alias: _91key_93FRAYFKWao5Meta?.alias || [],
    redirect: _91key_93FRAYFKWao5Meta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/payment/edu/[key].vue").then(m => m.default || m)
  },
  {
    name: index1ktBhE27GTMeta?.name ?? "payment-edu",
    path: index1ktBhE27GTMeta?.path ?? "/payment/edu",
    meta: index1ktBhE27GTMeta || {},
    alias: index1ktBhE27GTMeta?.alias || [],
    redirect: index1ktBhE27GTMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/payment/edu/index.vue").then(m => m.default || m)
  },
  {
    name: errorq6gs1yJodgMeta?.name ?? "payment-error",
    path: errorq6gs1yJodgMeta?.path ?? "/payment/error",
    meta: errorq6gs1yJodgMeta || {},
    alias: errorq6gs1yJodgMeta?.alias || [],
    redirect: errorq6gs1yJodgMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/payment/error.vue").then(m => m.default || m)
  },
  {
    name: fail9trekHFPLnMeta?.name ?? "payment-fail",
    path: fail9trekHFPLnMeta?.path ?? "/payment/fail",
    meta: fail9trekHFPLnMeta || {},
    alias: fail9trekHFPLnMeta?.alias || [],
    redirect: fail9trekHFPLnMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/payment/fail.vue").then(m => m.default || m)
  },
  {
    name: indexzszgv4uWHEMeta?.name ?? "payment",
    path: indexzszgv4uWHEMeta?.path ?? "/payment",
    meta: indexzszgv4uWHEMeta || {},
    alias: indexzszgv4uWHEMeta?.alias || [],
    redirect: indexzszgv4uWHEMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: processaJDvwYHzk5Meta?.name ?? "payment-process",
    path: processaJDvwYHzk5Meta?.path ?? "/payment/process",
    meta: processaJDvwYHzk5Meta || {},
    alias: processaJDvwYHzk5Meta?.alias || [],
    redirect: processaJDvwYHzk5Meta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/payment/process.vue").then(m => m.default || m)
  },
  {
    name: successWlaXXQvILvMeta?.name ?? "payment-success",
    path: successWlaXXQvILvMeta?.path ?? "/payment/success",
    meta: successWlaXXQvILvMeta || {},
    alias: successWlaXXQvILvMeta?.alias || [],
    redirect: successWlaXXQvILvMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/payment/success.vue").then(m => m.default || m)
  },
  {
    name: token212h2HiisuMeta?.name ?? "redirect-token",
    path: token212h2HiisuMeta?.path ?? "/redirect/token",
    meta: token212h2HiisuMeta || {},
    alias: token212h2HiisuMeta?.alias || [],
    redirect: token212h2HiisuMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/redirect/token.vue").then(m => m.default || m)
  },
  {
    name: index92fi2Src5eMeta?.name ?? "reject",
    path: index92fi2Src5eMeta?.path ?? "/reject",
    meta: index92fi2Src5eMeta || {},
    alias: index92fi2Src5eMeta?.alias || [],
    redirect: index92fi2Src5eMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/reject/index.vue").then(m => m.default || m)
  },
  {
    name: index8yXF8Gf3pxMeta?.name ?? "setting",
    path: index8yXF8Gf3pxMeta?.path ?? "/setting",
    meta: index8yXF8Gf3pxMeta || {},
    alias: index8yXF8Gf3pxMeta?.alias || [],
    redirect: index8yXF8Gf3pxMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/setting/index.vue").then(m => m.default || m)
  },
  {
    name: privacyrxV8HlafuGMeta?.name ?? "setting-privacy",
    path: privacyrxV8HlafuGMeta?.path ?? "/setting/privacy",
    meta: privacyrxV8HlafuGMeta || {},
    alias: privacyrxV8HlafuGMeta?.alias || [],
    redirect: privacyrxV8HlafuGMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/setting/privacy.vue").then(m => m.default || m)
  },
  {
    name: termgWVbHutvx7Meta?.name ?? "setting-term",
    path: termgWVbHutvx7Meta?.path ?? "/setting/term",
    meta: termgWVbHutvx7Meta || {},
    alias: termgWVbHutvx7Meta?.alias || [],
    redirect: termgWVbHutvx7Meta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/setting/term.vue").then(m => m.default || m)
  },
  {
    name: _01QzDrv3ehTZMeta?.name ?? "test-01",
    path: _01QzDrv3ehTZMeta?.path ?? "/test/01",
    meta: _01QzDrv3ehTZMeta || {},
    alias: _01QzDrv3ehTZMeta?.alias || [],
    redirect: _01QzDrv3ehTZMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/01.vue").then(m => m.default || m)
  },
  {
    name: audioNgg0U5xcncMeta?.name ?? "test-audio",
    path: audioNgg0U5xcncMeta?.path ?? "/test/audio",
    meta: audioNgg0U5xcncMeta || {},
    alias: audioNgg0U5xcncMeta?.alias || [],
    redirect: audioNgg0U5xcncMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/audio.vue").then(m => m.default || m)
  },
  {
    name: bottomUgNFETqhQ8Meta?.name ?? "test-bottom",
    path: bottomUgNFETqhQ8Meta?.path ?? "/test/bottom",
    meta: bottomUgNFETqhQ8Meta || {},
    alias: bottomUgNFETqhQ8Meta?.alias || [],
    redirect: bottomUgNFETqhQ8Meta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/bottom.vue").then(m => m.default || m)
  },
  {
    name: bottom29kJlwcmedqMeta?.name ?? "test-bottom2",
    path: bottom29kJlwcmedqMeta?.path ?? "/test/bottom2",
    meta: bottom29kJlwcmedqMeta || {},
    alias: bottom29kJlwcmedqMeta?.alias || [],
    redirect: bottom29kJlwcmedqMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/bottom2.vue").then(m => m.default || m)
  },
  {
    name: bottom36vF6EWTtMlMeta?.name ?? "test-bottom3",
    path: bottom36vF6EWTtMlMeta?.path ?? "/test/bottom3",
    meta: bottom36vF6EWTtMlMeta || {},
    alias: bottom36vF6EWTtMlMeta?.alias || [],
    redirect: bottom36vF6EWTtMlMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/bottom3.vue").then(m => m.default || m)
  },
  {
    name: children_select_01JYgeT4yPq5Meta?.name ?? "test-children_select_01",
    path: children_select_01JYgeT4yPq5Meta?.path ?? "/test/children_select_01",
    meta: children_select_01JYgeT4yPq5Meta || {},
    alias: children_select_01JYgeT4yPq5Meta?.alias || [],
    redirect: children_select_01JYgeT4yPq5Meta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/children_select_01.vue").then(m => m.default || m)
  },
  {
    name: children_select_02KSgemCFzVBMeta?.name ?? "test-children_select_02",
    path: children_select_02KSgemCFzVBMeta?.path ?? "/test/children_select_02",
    meta: children_select_02KSgemCFzVBMeta || {},
    alias: children_select_02KSgemCFzVBMeta?.alias || [],
    redirect: children_select_02KSgemCFzVBMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/children_select_02.vue").then(m => m.default || m)
  },
  {
    name: children_select0Mvwetm2CsMeta?.name ?? "test-children_select",
    path: children_select0Mvwetm2CsMeta?.path ?? "/test/children_select",
    meta: children_select0Mvwetm2CsMeta || {},
    alias: children_select0Mvwetm2CsMeta?.alias || [],
    redirect: children_select0Mvwetm2CsMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/children_select.vue").then(m => m.default || m)
  },
  {
    name: circle_45chartFLDUa0dYXVMeta?.name ?? "test-circle-chart",
    path: circle_45chartFLDUa0dYXVMeta?.path ?? "/test/circle-chart",
    meta: circle_45chartFLDUa0dYXVMeta || {},
    alias: circle_45chartFLDUa0dYXVMeta?.alias || [],
    redirect: circle_45chartFLDUa0dYXVMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/circle-chart.vue").then(m => m.default || m)
  },
  {
    name: datepickerarSCkMwc80Meta?.name ?? "test-datepicker",
    path: datepickerarSCkMwc80Meta?.path ?? "/test/datepicker",
    meta: datepickerarSCkMwc80Meta || {},
    alias: datepickerarSCkMwc80Meta?.alias || [],
    redirect: datepickerarSCkMwc80Meta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/datepicker.vue").then(m => m.default || m)
  },
  {
    name: deviceAHKZYpW65aMeta?.name ?? "test-device",
    path: deviceAHKZYpW65aMeta?.path ?? "/test/device",
    meta: deviceAHKZYpW65aMeta || {},
    alias: deviceAHKZYpW65aMeta?.alias || [],
    redirect: deviceAHKZYpW65aMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/device.vue").then(m => m.default || m)
  },
  {
    name: edit0z2FWIcUhNMeta?.name ?? "test-edit",
    path: edit0z2FWIcUhNMeta?.path ?? "/test/edit",
    meta: edit0z2FWIcUhNMeta || {},
    alias: edit0z2FWIcUhNMeta?.alias || [],
    redirect: edit0z2FWIcUhNMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/edit.vue").then(m => m.default || m)
  },
  {
    name: iframe1C7NpVMFtNMeta?.name ?? "test-iframe",
    path: iframe1C7NpVMFtNMeta?.path ?? "/test/iframe",
    meta: iframe1C7NpVMFtNMeta || {},
    alias: iframe1C7NpVMFtNMeta?.alias || [],
    redirect: iframe1C7NpVMFtNMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/iframe.vue").then(m => m.default || m)
  },
  {
    name: image_45pickerEmvATCLfiYMeta?.name ?? "test-image-picker",
    path: image_45pickerEmvATCLfiYMeta?.path ?? "/test/image-picker",
    meta: image_45pickerEmvATCLfiYMeta || {},
    alias: image_45pickerEmvATCLfiYMeta?.alias || [],
    redirect: image_45pickerEmvATCLfiYMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/image-picker.vue").then(m => m.default || m)
  },
  {
    name: indexwkgGO797dlMeta?.name ?? "test",
    path: indexwkgGO797dlMeta?.path ?? "/test",
    meta: indexwkgGO797dlMeta || {},
    alias: indexwkgGO797dlMeta?.alias || [],
    redirect: indexwkgGO797dlMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/index.vue").then(m => m.default || m)
  },
  {
    name: orientationm4jZdZtOkxMeta?.name ?? "test-orientation",
    path: orientationm4jZdZtOkxMeta?.path ?? "/test/orientation",
    meta: orientationm4jZdZtOkxMeta || {},
    alias: orientationm4jZdZtOkxMeta?.alias || [],
    redirect: orientationm4jZdZtOkxMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/orientation.vue").then(m => m.default || m)
  },
  {
    name: photo_select_01541Yv1wTjzMeta?.name ?? "test-photo_select_01",
    path: photo_select_01541Yv1wTjzMeta?.path ?? "/test/photo_select_01",
    meta: photo_select_01541Yv1wTjzMeta || {},
    alias: photo_select_01541Yv1wTjzMeta?.alias || [],
    redirect: photo_select_01541Yv1wTjzMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/photo_select_01.vue").then(m => m.default || m)
  },
  {
    name: photo_select_02VjKXe5fg8gMeta?.name ?? "test-photo_select_02",
    path: photo_select_02VjKXe5fg8gMeta?.path ?? "/test/photo_select_02",
    meta: photo_select_02VjKXe5fg8gMeta || {},
    alias: photo_select_02VjKXe5fg8gMeta?.alias || [],
    redirect: photo_select_02VjKXe5fg8gMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/photo_select_02.vue").then(m => m.default || m)
  },
  {
    name: photoFFGVNGzZ1lMeta?.name ?? "test-photo",
    path: photoFFGVNGzZ1lMeta?.path ?? "/test/photo",
    meta: photoFFGVNGzZ1lMeta || {},
    alias: photoFFGVNGzZ1lMeta?.alias || [],
    redirect: photoFFGVNGzZ1lMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/photo.vue").then(m => m.default || m)
  },
  {
    name: text7TwdG0GhwvMeta?.name ?? "test-text",
    path: text7TwdG0GhwvMeta?.path ?? "/test/text",
    meta: text7TwdG0GhwvMeta || {},
    alias: text7TwdG0GhwvMeta?.alias || [],
    redirect: text7TwdG0GhwvMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/text.vue").then(m => m.default || m)
  },
  {
    name: tossbld0mqu8BMMeta?.name ?? "test-toss",
    path: tossbld0mqu8BMMeta?.path ?? "/test/toss",
    meta: tossbld0mqu8BMMeta || {},
    alias: tossbld0mqu8BMMeta?.alias || [],
    redirect: tossbld0mqu8BMMeta?.redirect || undefined,
    component: () => import("D:/DYB_Project/DYB-Windos-Nuxt/pages/test/toss.vue").then(m => m.default || m)
  }
]