declare type AxiosHeaders =
  | 'application/json'
  | 'application/x-www-form-urlencoded'
  | 'multipart/form-data'

declare type AxiosMethod = 'get' | 'post' | 'delete' | 'put'

declare type AxiosResponseType =
  | 'arraybuffer'
  | 'blob'
  | 'document'
  | 'json'
  | 'text'
  | 'stream'

export interface IResponse<T = any> {
  code: string
  data: T extends any ? T : T & any
}

const config: {
  result_code: number | string
  token_expire_code: number | string
  token_error_code: number | string
  token_error_reject_code: number | string
  access_error_code: number | string
  reject_code: number | string
  default_headers: AxiosHeaders
  request_timeout: number
  withe_result_codes: [string]
} = {
  /**
   * The interface returns a status code successfully
   */
  result_code: '100',

  /** token expire*/
  token_error_reject_code: '301',

  /** token expire*/
  token_expire_code: '302',

  /** token error*/
  token_error_code: '309',

  access_error_code: '701',

  reject_code: '601',
  /**
   * Interface request timeout
   */
  request_timeout: 300000,

  /**
   * Default interface request type
   * application/x-www-form-urlencoded multipart/form-data
   */
  default_headers: 'application/json',
  withe_result_codes: ['601'],
}

export { config }
